import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { signOut } from "../../containers/auth/redux_actions";
import "./header.css";
import { useMediaQuery, Drawer, Grid, Button } from "@material-ui/core";
import { LockOutlined } from "@material-ui/icons";

const Header = (props) => {
  const [open, setOpen] = useState(false);
  let app = useSelector((state) => state.app);
  let auth = useSelector((state) => state.auth);
  const modules = app.myModules;
  const isDesktop = useMediaQuery("(min-width:980px)");
  const { showGrid, desc } = props;
  let { userAccount } = useSelector((state) => state.app);
  return (
    <React.Fragment>
      <div id="header-bar">
        <div className="site-logo-cntr">
          <div
            style={{
              height: "100%",
            }}
          >
            <Link to={"/"}>
              {isDesktop ? (
                <img alt="site-logo" src="/images/logo-desktop.svg" />
              ) : (
                <img alt="site-logo" src="/images/logo-mob.svg" />
              )}
            </Link>
          </div>
        </div>
        {showGrid && !isDesktop && (
          <div className="menu" onClick={() => setOpen(!open)}>
            <img src="/images/spaces-menu-icon.svg" alt="icon" />
          </div>
        )}
        {isDesktop && (
          <div style={{ display: "flex", fontWeight: 500 }}>
            <div
              style={{
                fontSize: 13,
                cursor: "pointer",
                lineHeight: "19px",
                color: "#979797",
              }}
            >
              {userAccount && userAccount.name ? userAccount.name : null}
            </div>
            <span
              style={{
                fontSize: 13,
                cursor: "pointer",
                lineHeight: "19px",
                color: "#979797",
              }}
            >
              &nbsp; | &nbsp;
            </span>
            <div
              style={{ fontSize: 13, cursor: "pointer" }}
              onClick={() => {
                props.signOut();
              }}
            >
              Logout
            </div>
          </div>
        )}
        <Drawer
          anchor={"left"}
          open={open}
          onClose={() => setOpen(!open)}
          className="drawer"
          PaperProps={{
            style: isDesktop ? { width: "343px" } : { width: "100%" },
          }}
        >
          <div style={{ padding: "30px" }}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <img
                onClick={() => setOpen(!open)}
                src="/images/close-btn.svg"
                alt="close"
                style={{ cursor: "pointer" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                color: "#979797",
                marginBottom: "24px",
              }}
            >
              <img
                src="/images/spaces-menu-icon.svg"
                alt="grid"
                style={{ cursor: "pointer" }}
              />
              <span style={{ margin: "0 0 0 15px" }}>
                Exploration <br /> Spaces
              </span>
            </div>
            <Grid container>
              {Object.keys(modules || {}).map((mId, i) => {
                return (
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={12}
                    lg={12}
                    key={mId}
                    style={{
                      padding: "16px 0px",
                      borderTop: "1px solid #BEBEBE",
                    }}
                  >
                    {modules[mId] && modules[mId].locked ? (
                      <div
                        style={{
                          display: "flex",
                          textDecoration: "none",
                          color: "inherit",
                          opacity:
                            modules[mId] && modules[mId].locked ? ".6" : 1,
                        }}
                      >
                        {renderModule(modules, mId, i)}
                      </div>
                    ) : (
                      <Link
                        to={
                          modules[mId] && modules[mId].locked
                            ? "#"
                            : `/app/module/${mId}`
                        }
                        style={{
                          display: "flex",
                          textDecoration: "none",
                          color: "inherit",
                          opacity:
                            modules[mId] && modules[mId].locked ? ".6" : 1,
                        }}
                      >
                        {renderModule(modules, mId, i)}
                      </Link>
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </Drawer>
      </div>
      {showGrid && isDesktop && (
        <div
          style={{
            display: "flex",
            color: "#979797",
            marginBottom: "24px",
            padding: "0 24px",
            cursor: "pointer",
            width: "200px",
          }}
          onClick={() => setOpen(!open)}
        >
          <img
            src="/images/spaces-menu-icon.svg"
            alt="grid"
            style={{ cursor: "pointer" }}
          />
          <span style={{ margin: "0 0 0 15px" }}>
            Exploration <br /> Spaces
          </span>
        </div>
      )}
    </React.Fragment>
  );
};

function renderModule(modules, mId, i) {
  return (
    <React.Fragment>
      <div
        style={{
          font: "normal normal 300 40px/50px Merriweather Sans",
        }}
      >
        {i + 1}
      </div>
      <div style={{ margin: "0 0 0 15px" }}>
        <div
          style={{
            marginTop: "10px",
            font: "normal normal normal 18px/26px Merriweather",
          }}
        >
          {modules[mId] && modules[mId].title}
        </div>
        {modules[mId] && modules[mId].locked ? (
          <div
            style={{
              textAlign: "left",
              font: "normal normal bold 12px/22px Merriweather Sans",
              letterSpacing: "0px",
              opacity: 1,
              padding: "10px 0 20px 0",
              display: "flex",
              alignItems: "center",
            }}
          >
            <LockOutlined style={{}} />{" "}
            <span style={{ height: "15px" }}>LOCKED</span>
          </div>
        ) : (
          <Button
            component={Link}
            style={{
              textAlign: "left",
              font: "normal normal bold 12px/22px Merriweather Sans",
              letterSpacing: "0px",
              color: "#E6111B",
              opacity: 1,
              padding: "10px 0 20px 0",
            }}
          >
            Explore
          </Button>
        )}
      </div>
    </React.Fragment>
  );
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ signOut }, dispatch);
}

export default connect(null, matchDispatchToProps)(Header);
