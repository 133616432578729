import React from 'react';
import ExternalResource from '../components/ExternalLink';
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return <div>
    <p >
    Is the concept of work-life balance a flawed one? In the articles and videos below, we explore multiple concepts related to work-life balance and work-life integration – where ‘purpose’ drives you to work and therefore ensures you contribute consistently at the workplace as well as in other parts of your life.  
    </p>
    <div className='f15_b' style={{ marginBottom: "20px" }}>
    While reading the articles below, keep an eye out for:
    </div>
    <ul>
      <li>The role that ‘trust’ plays in work – life integration. </li>
      <li>The meaning of “four-way wins”.</li>
      <li>The three key principles – to be real, be whole, and be innovative through work.</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://gembamarketing.com/the-millennial-shift-work-life-balance-vs-work-life-integration/' />
      <ExternalResource url='https://hbr.org/2014/10/what-successful-work-and-life-integration-looks-like?registration=success' />
      </div>
  </div>
}