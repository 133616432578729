import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import store from "../../Redux_Store";

if (!firebase.apps.length) {
  const config = {
    apiKey: "AIzaSyC7wfwLtOf3RhyelZOz9u8jiMzda5jeDGI",
    authDomain: "coaching-platform-illumine.firebaseapp.com",
    databaseURL: "https://coaching-platform-illumine.firebaseio.com",
    projectId: "coaching-platform-illumine",
    storageBucket: "coaching-platform-illumine.appspot.com",
    messagingSenderId: "1002955816731",
    appId: "1:1002955816731:web:0fd922865f30a3f5499dbe",
    measurementId: "G-XS8GS3VLEH",
  };
  firebase.initializeApp(config);
}
let db = firebase.database();

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    store.dispatch({ type: "UPDATE_USERID", data: user.uid });

    db.ref(`/users/${user.uid}/userAccount`).on("value", (snapshot) => {
      store.dispatch({ type: "UPDATE_USER_ACCOUNT", data: snapshot.val() });
    });

    db.ref(`/users/${user.uid}/mySubscriptions`)
      .orderByChild("app")
      .equalTo("fosExplorium")
      .on("value", (snapshot) => {
        let d = snapshot.val();
        if (d) {
          Object.keys(d).forEach((el) => {
            let m = d[el];
            db.ref(`modules/${el}`)
              .on("value", (s) => {
                let md = s.val()||{};
                // console.log(m);
                // md.unlocked = m.unlocked||false;
                // md.locked = !m.unlocked;
                store.dispatch({
                  type: "UPDATE_MODULES",
                  data: { id: el, detail: md },
                });

                if (md && md.units) {
                  Object.keys(md.units).map((uId) => {
                    db.ref(`exploriumUnits/${uId}`)
                      .once("value")
                      .then((u) => {
                        let unit = u.val();
                        store.dispatch({
                          type: "UPDATE_UNITS",
                          data: { id: uId, detail: unit },
                        });
                      });
                  });
                }
              });
          });
        }
      });
  } else {
    //console.log("no user");
  }
});
