import React from 'react';
import ExternalResource from '../components/ExternalLink';
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return <div>
    <p >
    We often hear people proudly proclaim they are perfectionists, but does perfectionism equal success? And what drives this perfectionism? Is it innate or do external expectations influence a person’s need to be a perfectionist? This article gives readers a perspective on attaching importance to an external view of success.
    </p>
    <div className='f15_b' style={{ marginBottom: "20px" }}>
    While reading this pay special attention to the following:
    </div>
    <ul>
      <li>The difference between adaptive and maladaptive perfectionism</li>
      <li>Some of the mental changes perfectionism leads to</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.bbc.com/future/article/20180219-toxic-perfectionism-is-on-the-rise' />
    </div>
  </div>
}