import React from 'react';
import ExternalResource from '../components/ExternalLink';
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return <div>
    <p >
    It is easy to get swept up by our emotions, to experience self-pity and feel powerless in situations. But, according to psychotherapist, Amy Morin, indulging in self-pity ensures you lose power over your self and the situation you are in.
    </p>
    <div className='f15_b' style={{ marginBottom: "20px" }}>
    While watching the video think of the following:
    </div>
    <ul>
      <li>What does Amy Morin classify as bad mental habit?</li>
      <li>The need to go into uncharted territory without a plan, but lots of passion and drive.</li>
      <li>The ways you can counter unhealthy beliefs and bad mental habits.</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.youtube.com/watch?v=TFbv757kup4' />
    </div>
  </div>
}