import React from "react";
import ExternalResource from "../components/ExternalLink";
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return (
    <div>
      <p>
        We often do not realise our own potential, and have a limited view of
        our own lives. Here are three different stories of people who struggled
        with a limited view of themselves and worked hard to create a new vision
        for their lives. The stories below are of Carrie Green, Tim Ferris and
        David Goggins and their journey. All three had to fight their own
        selves, conquer their own fears, and change their patterns of thinking
        to unleash their potential.
      </p>
      <div className="f15_b" style={{ marginBottom: "10px" }}>
        While reading, keep an eye out for:
      </div>
      <ul>
        <li>
          The limiting thoughts Carrie Green, Time Ferris and David Goggins were
          struggling with
        </li>
        <li>
          Their personal “I Can” moments:
          <ul>
            <li>
              Think of the transformation Carrie Green experienced after she
              confronted her self - limiting beliefs.
            </li>
            <li>
              Think of how Tim Ferris developed a method to confront his fears
              to build a new life for himself.
            </li>
            <li>
              How David Goggins faced his fear of being a nobody and redefined
              his life goals
            </li>
          </ul>
        </li>
        <li>
          The fact that their need to change came from within them and not from
          an external source
        </li>
      </ul>
      <p>
        (Note: The stories mentioned here are only to showcase how people
        unleash their potential. In their journey they have taken risks and even
        caused bodily harm to themselves. We do not ask you to emulate their
        behaviour, but to understand what motivated them.)
      </p>
      <div className="highlighted-patch">
        <div className="f15_b">Links</div>
        <ExternalResource url="https://www.youtube.com/watch?v=MmfikLimeQ8" />
        {/* <ExternalResource url='https://www.youtube.com/watch?v=iPE2_iCCo0w' /> */}
        <ExternalResource url="https://www.youtube.com/watch?v=5J6jAC6XxAI" />
        <ExternalResource url="https://www.youtube.com/watch?v=X3yNsomAUvw" />
      </div>
    </div>
  );
};
