import React from 'react';
import ExternalResource from '../components/ExternalLink';
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return <div>
    <p >
    Farah Storr spent most of her life avoiding discomfort. It was only when she moved to another country for work that she realised the value of discomfort. In facing her fears and apprehensions, Farah was able to discover a new dimension of herself and her own inner strength. The video below showcases how discomfort played a very important role in her own journey of growth.
    </p>
    <div className='f15_b' style={{ marginBottom: "20px" }}>
    While watching the video pay attention to the following:
    </div>
    <ul>
      <li>The reason why Farah Storr says,”The world doesn’t change but you become braver and deal with things better.”</li>
      <li>The idea of coddling ourselves, and why Farah warns against it.</li>
      <li>Due to their internal motivation they managed to make a difference in the lives of many Indians.</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.youtube.com/watch?v=X48jYA43Uck&feature=youtu.be' />
    </div>
  </div>
}