import React from 'react';
import ExternalResource from '../components/ExternalLink';
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return <div>
    <p >
    We may feel pity for ourselves or even feel sorry about ourselves when we are faced with a tragedy. The real problem arises when you dwell on this mindset for too long that it becomes an inextricable part of your life. Victimhood can skew your view of your own life and of others. In this article we explore how interpersonal victimhood can negatively impact your life.
    </p>
    <div className='f15_b' style={{ marginBottom: "20px" }}>
    While reading this article, please pay special attention to the following:
    </div>
    <ul>
      <li>How the founders kept pushing ahead despite initial challenges.</li>
      <li>The three main biases (interpretation, attribution, and memory biases) that contribute to feelings of interpersonal victimhood.</li>
    </ul>
    <p >
    Remember not to use these aspects to ‘label’ or judge yourself but as signals to recognize each time, unknowingly you may be acting out of sense of victimhood. Instead focus on the key go-forward given at the end of this article –Move from a ‘victimhood’ to ‘personal growth’ mindset. The program has given you the strategies to do so.
    </p >
      <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.scientificamerican.com/article/unraveling-the-mindset-of-victimhood/' />
    </div>
  </div>
}