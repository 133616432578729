import React from "react";
import ExternalResource from "../components/ExternalLink";
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return (
    <div>
      <p>
        It has been a challenging year for the entire planet, but it has also
        been a year of reflection and growth for many. The people in the stories
        shared below may have been cut off from others, but to them, this time
        of isolation was a moment of learning. They learnt to connect and listen
        to their inner selves and use that knowledge for growth.
      </p>
      <div className="f15_b" style={{ marginBottom: "10px" }}>
        While reading, keep an eye out for:
      </div>
      <ul>
        <li>
          What it takes to understand your true self when you are cut off from
          the outside world and expected to slow down. For eg. Kimmie Corner,
          has discovered that her vision of herself before she went into
          isolation has changed drastically after she went into self-isolation.
        </li>
        <li>
          How did people find happiness or contentment while dealing with
          difficult circumstances, For eg. Alexa Cucchiara, has lost business
          opportunities during the Pandemic, but this has not perturbed her
          spirit, in fact it has led her to think of new ways to do business.
        </li>
      </ul>

      <div className="highlighted-patch">
        <div className="f15_b">Links</div>
        <ExternalResource url="https://www.insider.com/people-share-what-theyve-learned-about-themselves-in-quarantine-2020-4?amp" />
      </div>
    </div>
  );
};
