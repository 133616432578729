import React from 'react';
import ExternalResource from '../components/ExternalLink';
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return <div>
    <p >
      Munaf Patel could have stayed in his comfortable job at Google, something most people dream of, but he had a mission and a purpose that went beyond the world’s definition of success.
    </p>
    <div className='f15_b' style={{ marginBottom: "20px" }}>
      While going through his story, pay special attention to:
    </div>
    <ul>
      <li>The true driving force for him to begin the Bohri kitchen and the way the meaning of success changed for him.</li>
      <li>His reaction to initial failure and what kept him going forward.</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.bbc.com/news/business-41467962' />
      <ExternalResource url='https://www.thebetterindia.com/231449/google-samosa-entrepreneur-bohri-food-dawoodi-bohra-nalli-nihari-chicken-biryani-traditional-recipes-mumbai-ang136' />
      <ExternalResource url='https://www.youtube.com/watch?v=VHXSb2H03WE' />
    </div>
  </div>
}