import React from "react";
import ExternalResource from "../components/ExternalLink";
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return (
    <div>
      <p>
        Have you ever looked within and thought of yourself as having infinite
        potential? As humans, we tend to have set beliefs about ourselves and
        our potential. And, hold on to these beliefs even if they do not serve
        us. In this article, author, Dr. Deepak Chopra, talks about the
        importance of shifting to the infinite potential mindset, and believing
        that you can achieve whatever it is you want.
      </p>
      <div className="f15_b" style={{ marginBottom: "20px" }}>
        While reading this article, pay special attention to the following:
      </div>
      <ul>
        <li>
          The difference between the finite vision of ourselves and an infinite
          vision of ourselves.
        </li>
        <li>
          The concept of being a metahuman, someone who understands that their
          own potential is unlimited. They are not bound by external measures of
          success or achievements. Their life goals are not set by what society
          expects them to do, but is a product of deep reflection and
          understanding of their own self.
        </li>
      </ul>

      <div className="highlighted-patch">
        <div className="f15_b">Links</div>
        <ExternalResource url="https://www.sfgate.com/opinion/chopra/article/Do-We-Really-Have-Infinite-Potential-14442492.php" />
      </div>
    </div>
  );
};
