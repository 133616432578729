import React from 'react';
import ExternalResource from '../components/ExternalLink';
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return <div>
    <p >
    The video below is about Jack Andraka, who at 15, a time when most teens are glued to their mobile phones, has managed to build a promising test for pancreatic cancer. He realises that even as a student he has the choice to contribute. He needn’t confine himself to only student activities and is widening his zone of contribution by building new innovations.  
    </p>
    <div className='f15_b' style={{ marginBottom: "20px" }}>
  In Jack’s story we find many examples of a contributor personality, look for the following moments:
    </div>
    <ul>
      <li>Jack’s “I Can” moment when he looks for a laboratory to build his product in and does not let his age or lack of experience deter him.</li>
      <li>The way he assesses the flaws in his product and tries to consistently improve it and learns from his failures.</li>
      <li>How he does not only have a goal but a vision for his work and the product that he is building.</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.ted.com/talks/jack_andraka_a_promising_test_for_pancreatic_cancer_from_a_teenager/discussion?referrer=playlist-talks_to_watch_with_kids' />
      </div>
  </div>
}