import React from 'react';
import ExternalResource from '../components/ExternalLink';
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return <div>
    <p >
    In the articles below, you will find key strategies to find meaning in the things you do. You may be assigned a certain role or a task, but that in no way needs to limit the amount you can contribute at the workplace. You can design your own role and build meaning and purpose in every task you are assigned.  While going through the articles, look at the key aspects of finding meaning and purpose in your work.  
    </p>
    <div className='f15_b' style={{ marginBottom: "20px" }}>
    Pay special attention to:
    </div>
    <ul>
      <li>The difference between finding purpose and building purpose in your life and work.</li>
      <li>Consciously connecting your job to the way in which it serves people.</li>
      <li>The idea of job crafting – and taking control of how you define your role and tasks.</li>
    </ul>
    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://hbr.org/2017/12/to-find-meaning-in-your-work-change-how-you-think-about-it' />
      <ExternalResource url='https://positivepsychology.com/job-crafting/' />
      </div>
  </div>
}