import React from "react";
import { Link } from "react-router-dom";
import { Button, Grid, useMediaQuery } from "@material-ui/core";
import { LockOutlined } from "@material-ui/icons";

export default ({ modules }) => {
  const isDesktop = useMediaQuery("(min-width:980px)");

  return (
    <div style={{}}>
      <Grid container spacing={6}>
        {Object.keys(modules || {}).map((mId, i) => {
          const isTopBorder = isDesktop
            ? i === 0 || i === 1 || i === 2
              ? true
              : false
            : i !== Object.keys(modules).length - 1
            ? true
            : false;
          return (
            modules[mId] && (
              <Grid
                item
                sm={12}
                xs={12}
                md={4}
                lg={4}
                key={mId}
                style={{ paddingBottom: 0, paddingTop: 0 }}
              >
                {modules[mId] && !modules[mId].locked ? (
                  <div
                    to={`/app/module/${mId}`}
                    style={{
                      display: "block",
                      textDecoration: "none",
                      color: "inherit",
                      opacity: modules[mId] && modules[mId].locked ? ".6" : 1,
                      borderTop: "1px solid #bebebe",
                      borderBottom: "1px solid #bebebe",
                      ...(isTopBorder ? { borderBottom: "none" } : ""),
                      paddingTop: "10px",
                      height: "338px",
                      position: "relative",
                    }}
                  >
                    {renderModule(modules, mId, i, isDesktop)}
                  </div>
                ) : (
                  <div
                    style={{
                      display: "block",
                      textDecoration: "none",
                      color: "inherit",
                      opacity: modules[mId] && modules[mId].locked ? ".6" : 1,
                      borderTop: "1px solid #bebebe",
                      borderBottom: "1px solid #bebebe",
                      ...(isTopBorder ? { borderBottom: "none" } : ""),
                      paddingTop: "10px",
                      height: "338px",
                      position: "relative",
                    }}
                  >
                    {renderModule(modules, mId, i, isDesktop)}
                  </div>
                )}
              </Grid>
            )
          );
        })}
      </Grid>
    </div>
  );
};

function renderModule(modules, mId, i, isDesktop) {
  return (
    <React.Fragment>
      <div
        style={{
          font: "normal normal 300 40px/50px Merriweather Sans",
        }}
      >
        {i + 1}
      </div>

      <Grid container>
        <Grid item md={8} sm={8} lg={8} xs={8}>
          <div
            style={{
              font: "normal normal normal 21px/30px Merriweather",
              fontWeight: 700,
              ...(isDesktop ? { margin: "12px 0px" } : { margin: "12px 0px" }),
            }}
          >
            {modules[mId] && modules[mId].title}
          </div>
          <div
            className="line-clamp line-clamp-mobile"
            style={{ color: "#505050", fontSize: "14px", lineHeight: "1.4" }}
          >
            {modules[mId] && modules[mId].description}
          </div>
          <div style={{ position: "absolute", bottom: 44, left: 0 }}>
            {modules[mId] && modules[mId].locked ? (
              <div
                style={{
                  textAlign: "left",
                  font: "normal normal 800 14px/22px Merriweather Sans",
                  letterSpacing: "0px",
                  opacity: 1,
                  padding: "20px 0 0px",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <LockOutlined style={{ marginRight: "0px" }} />{" "}
                <span style={{ height: "19px" }}>LOCKED</span>
              </div>
            ) : (
              <Link
                className="e-link"
                to={
                  modules[mId] && modules[mId].locked
                    ? "#"
                    : `/app/module/${mId}`
                }
                style={{
                  textAlign: "left",
                  font: "normal normal 800 14px/22px Merriweather Sans",
                  letterSpacing: "0px",
                  color: "#E6111B",
                  opacity: 1,
                  padding: "20px 0",
                  textTransform: "uppercase",
                  textDecoration: "none",
                }}
              >
                Explore
              </Link>
            )}
          </div>
        </Grid>
        <Grid item md={4} sm={4} lg={4} xs={4}>
          <div
            className="icon-wrapper"
            style={{
              // height: "100%",
              display: "flex",
              alignItems: "flex-end",
              position: "absolute",
              bottom: 44,
            }}
          >
            <img
              src={modules[mId] && modules[mId].icon}
              alt="m-icon"
              style={{ width: "100%", maxHeight: "100px" }}
            />
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
