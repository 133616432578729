import React from 'react';
import ExternalResource from '../components/ExternalLink';
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return <div>
    <p >
    In this video, Varun Agarwal shares his story of failing to meet expectations set for him by his family. However, what we learn is that by following his own idea of what success looks like, and not conforming to societal expectations, Varun was able to break barriers and carve out a strong identity and career for himself. Ultimately, his internal drive to succeed on his own terms drove him to excellence.
    </p>
    <div className='f15_b' style={{ marginBottom: "20px" }}>
    While watching the video, keep in mind:
    </div>
    <ul>
      <li>What Varun means when he says, “stop thinking so much”</li>
      <li>His greatest moment of success came from seeing his ideas into action and not money</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.youtube.com/watch?v=nMPqsjuXDmE' />
    </div>
  </div>
}