import React from 'react';
import ExternalResource from '../components/ExternalLink';
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return <div>
    <p >
    The article below gives us perspectives from varied disciplines and cultures on the idea of self-pity, self-absorption, and the victim mindset.
    </p>
    <div className='f15_b' style={{ marginBottom: "20px" }}>
    While reading the article pay special attention to the following:
    </div>
    <ul>
      <li>The idea that we create our own burdens.</li>
      <li>The three aspects of self-betrayal conduct.</li>
      <li>The meaning of the following line from the text - Marcus Aurelius put it this way in his Meditations: “It can ruin your life only if it ruins your character. Otherwise, it cannot harm you—inside or out.”</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://fee.org/articles/responsibility-is-the-antidote-to-mental-enslavement/' />
    </div>
  </div>
}