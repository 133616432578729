import React from "react";
import { useSelector } from "react-redux";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Grid, Drawer, useMediaQuery } from "@material-ui/core";
import { Link } from "react-router-dom";
export default (props) => {
  let { myModules, units } = useSelector((state) => state.app);
  let { mId } = props.match.params;
  let md = myModules[mId];
  const isDesktop = useMediaQuery("(min-width:980px)");
  return (
    <div className={isDesktop ? "desktop" : "mobile"}>
      <Header showGrid={true} />
      <div
        style={{
          ...(isDesktop
            ? { padding: "0px 106px 0 24px" }
            : { padding: "0 24px", marginBottom: "60px" }),
        }}
      >
        <Grid container>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <div style={{ ...(isDesktop ? { marginRight: "89px" } : "") }}>
              <div
                style={{
                  font: "normal normal bold 28px/35px Merriweather",
                  marginBottom: "20px",
                }}
              >
                {md.title}
              </div>
              <div
                style={{
                  font: "normal normal normal 14px/22px Merriweather Sans",
                  paddingBottom: 24,
                  color: "#505050",
                  marginBottom: "20px",
                }}
              >
                {md.description}
              </div>
            </div>
          </Grid>
          <Grid item lg={8} md={8} sm={4} xs={12}>
            <Grid container spacing={3}>
              {md.units &&
                Object.keys(md.units || {}).map((uId, i) => {
                  const isTopBorder = isDesktop
                    ? i === 0 || i === 1 || i === 2
                      ? true
                      : false
                    : i === 0 || i === 1
                    ? true
                    : false;
                  return (
                    <Grid
                      item
                      md={4}
                      lg={4}
                      xs={6}
                      key={i}
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                    >
                      {unitCard(units[uId], uId, mId, isTopBorder)}
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

function unitCard(unit, uId, mId, isTopBorder, isDesktop) {
  return (
    <Link
      key={uId}
      to={`/app/module/${mId}/explore/${uId}`}
      style={{
        flex: "0 1 150px",
        ...(isTopBorder ? { borderTop: "1px solid #bebebe" } : ""),
        borderBottom: "1px solid #bebebe",
        padding: "30px 0 70px",
        display: "block",
        color: "inherit",
        textDecoration: "none",
        height: "100%",
        position: "relative",
      }}
    >
      <div
        style={{
          paddingBottom: "10px",
          width: "38px",
          height: "38px",
          marginBottom: 13,
        }}
      >
        <img src={unit.icon} alt="icon" style={{ width: "100%" }} />
      </div>
      <div
        className={
          isDesktop
            ? "line-clamp line-clamp-desktop"
            : "line-clamp line-clamp-mobile"
        }
        style={{
          font: "normal normal bold 15px/23px Merriweather",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          lineClamp: "4",
          boxOrient: "vertical",
          marginBottom: "20px",
          overflow: "hidden",
          lineHeight: 1.6,
        }}
      >
        {unit.title}
      </div>
      <div
        style={{
          color: "#E6111B",
          font: "normal normal 800 11px/22px Merriweather Sans",
          textAlign: "right",
          position: "absolute",
          right: 0,
          bottom: "10px",
        }}
      >
        VIEW
      </div>
    </Link>
  );
}
