import React from 'react';
import ExternalResource from '../components/ExternalLink';
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return <div>
    <p >
    In the links below are the stories of three different men who approached their careers with contribution in mind. Verghese Kurien, T.N. Seshan, and M.S. Swaminathan, all adopted a contributor approach, while thinking about their work and the way their work could make an impact to those around them.  
    </p>
    <div className='f15_b' style={{ marginBottom: "20px" }}>
    While reading their stories, think of:
    </div>
    <ul>
      <li>Their unique approach to work and creating impact through their work.</li>
      <li>The fact that Verghese Kurien could make a massive contribution even when he was posted in a small town.</li>
      <li>M.S. Swaminathan’s sense of purpose – the fact that he saw many around him dying of hunger and decided to devote his career to do something about it. </li>
      <li>T.N. Seshan, who could have led a comfortable life by following the herd, but decided to make a difference and shake up the system he was working in, often at grave risk to his own self</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.mansworldindia.com/currentedition/from-the-magazine/ms-swaminathan-green-revolutionary/' />
      <ExternalResource url='https://www.businesstoday.in/management/leadership/verghese-kurien-made-amul-a-force-to-reckon-with/story/187924.html' />
      <ExternalResource url='https://theprint.in/india/only-god-or-tn-seshan-why-politicians-feared-the-ex-cec-who-was-an-inspiration-to-many/319134/' />
      <ExternalResource url='https://economictimes.indiatimes.com/news/politics-and-nation/tn-seshans-bold-initiatives-led-indias-game-changing-electoral-reforms/articleshow/72002688.cms' />
      </div>
  </div>
}