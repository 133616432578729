import React from 'react';
import ExternalResource from '../components/ExternalLink';
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return <div>
    <p >
    If you were to view identity as static, then it would never really evolve or change. But that is never really the case. Even in your own daily lives you play many roles, and how you identify yourself changes from moment to moment. This is a long read, that talks about how power and identity can play a role in making your identity stronger or weaker depending on the situation you are in.
    </p>
    <div className='f15_b' style={{ marginBottom: "20px" }}>
    While reading this pay special attention to:
    </div>
    <ul>
      <li>The fact that the law discriminated against black women, by virtue of the fact that it did not make space for them.</li>
      <li>How an identity can change from situation to situation, and not acknowledging unique identities can lead to systemic challenges.</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://chicagounbound.uchicago.edu/cgi/viewcontent.cgi?article=1052&context=uclf' />
    </div>
  </div>
}