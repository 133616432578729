import React from "react";
import ExternalResource from "../components/ExternalLink";
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return (
    <div>
      <p>
        Today, Urban Clap is a household name, with millions of people using
        their services. But, when Urban Clap founders, Abhiraj Bhal and Varun
        Khaitan decided to quit their high paying USA based jobs to start their
        own business in India, they did not have a concrete plan. What they did
        know was that they wanted to start up and it was time for them to take
        the plunge.
      </p>
      <div className="f15_b" style={{ marginBottom: "10px" }}>
        Keep an eye out for these “I Can” moments:
      </div>
      <ul>
        <li>How the founders kept pushing ahead despite initial challenges.</li>
        <li>
          The need to go into uncharted territory without a plan, but lots of
          passion and drive.
        </li>
        <li>
          Due to their internal motivation they managed to make a difference in
          the lives of many Indians.
        </li>
      </ul>

      <div className="highlighted-patch">
        <div className="f15_b">Links</div>
        <ExternalResource url="https://www.livemint.com/Companies/ck6h9KKDL9Aw1SCgzvMLRM/The-building-of-UrbanClap-one-ondemand-service-at-a-time.html" />
        <ExternalResource url="https://www.startupstories.in/stories/inspirational-stories/urbanclap-and-how-it-came-to-what-it-is-today" />
        <ExternalResource url="https://www.fortuneindia.com/enterprise/are-you-being-served/103554#:~:text=It%20was%20started%20in%202014,from%20pedicures%20to%20painting%20houses.&text=Abhiraj%20Bhal%2C%20founder%2C%20UrbanClap" />
      </div>
    </div>
  );
};
