import React from 'react';
import ExternalResource from '../components/ExternalLink';
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return <div>
    <p >
    We are often asked about the career paths we want to choose, or the proverbial, “Who do you want to be?” question. But before we choose what we want to be, a crucial part of the story involves understanding who we are and then building our careers with that in mind. In the video below, adult developmental psychologist and career counselor Sharon Belden Castonguay offers a new perspective to understand career decisions in the new world of work.  
    </p>
    <div className='f15_b' style={{ marginBottom: "20px" }}>
    While listening to the video, focus on the following insights:
    </div>
    <ul>
      <li>The shift in work landscape over time – Fourth industrial revolution.</li>
      <li>Why ‘career counselling & testing’ no longer helps, nor do mottos like ‘follow your passion” for making career decisions.</li>
      <li>How careers can be created iteratively through a process of design.</li>
      <li>The important of self-awareness – and the role of our ‘identity’ or who we are or want to become – in creating our own career story.</li>
    </ul>

    <div className='f15_b' style={{ marginBottom: "20px" }}>
    Based on these insights, reflect:
    </div>
    <ul>
      <li>What if you approach your career with a ‘Contributor identity’ or a vision of yourself as having ‘infinite possibilities’? How would your career story look?</li>
    </ul>


    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.youtube.com/watch?v=4e6KSaCxcHs' />
      </div>
  </div>
}