import React from 'react';
import ExternalResource from '../components/ExternalLink';
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return <div>
    <p >
    The links below tell the story of Dorothy Vaughan, and her fellow human computers. These were all black women, who were initially asked to work in a segregated section, away from their white colleagues. Even though the administration did not treat them well, they had enough self-belief and inner strength to make significant contributions to NASA. So great was their work that NASA honours them even till the present day.
    </p>
    <div className='f15_b' style={{ marginBottom: "20px" }}>
    While reading the two articles below, think of the following: 
    </div>
    <ul>
      <li>How Dorothy Vaughan stood up for her team and their welfare</li>
      <li>What did her “trailblazing career” and never give up attitude achieve for women who followed in her footsteps.</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://interestingengineering.com/dorothy-vaughan-nasas-human-computer-and-american-hero'/>
      <ExternalResource url='https://www.nasa.gov/content/dorothy-vaughan-biography'/>
    </div>
  </div>
}