import React from 'react';
import ExternalResource from '../components/ExternalLink';
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return <div>
    <p >
    We may think of our own identity as built by our culture, or by our biological or physiological markers. But, those aren’t the only attributes that shape our identity. We become who we are because of shared ideas, ideologies and how we view the world around us. Learn more about how the world shapes us in this article.
    </p>
    <div className='f15_b' style={{ marginBottom: "20px" }}>
    While reading the article pay special attention to:
    </div>
    <ul>
      <li>The fact that social and cultural identity is inextricably linked to issues of power, value systems, and ideology.</li>
      <li>The role the media plays in perpetuating identities, that may be true or false.</li>
          </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://criticalmediaproject.org/why-identity-matters/' />
    </div>
  </div>
}