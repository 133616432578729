const INITIAL_STATE = {
  units: null,
  userAccount: null,
  myModules: null,
  userId: null,
  currentModule: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "Navigation/NAVIGATE":
      return state;
    case "RESET_APP":
      return { ...INITIAL_STATE };
    case "UPDATE_USERID":
      return { ...state, userId: action.data };
    case "UPDATE_USER_ACCOUNT":
      return { ...state, userAccount: action.data };
    case "UPDATE_MODULES":
      return { ...state, myModules: { ...state.myModules, [action.data.id]: action.data.detail } };
    case "UPDATE_UNITS":
      return { ...state, units: { ...state.units, [action.data.id]: action.data.detail } };
    case "CURRENT_MODULE":
      return { ...state, units: { ...state.units, [action.data.id]: action.data.detail } };
    default:
      return state;
  }
};
