import React from 'react';
import ExternalResource from '../components/ExternalLink';
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return <div>
    <p >
    Following the herd may seem easy, but it can often lead to detrimental results. One of the biggest reasons not to follow the herd is that you would do so for the wrong reasons. We often think of money and comfort as a barometer for success, but true career success lies elsewhere, it lies in doing a job that has a purpose.   
    </p>
    <div className='f15_b' style={{ marginBottom: "20px" }}>
    While reading this article, pay special attention to:
    </div>
    <ul>
      <li>The lesson the writer has learnt from his own career.</li>
      <li>How the herd mentality leads to ending up with jobs that are oversaturated and how this impacts your own career development.</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.forbes.com/sites/jackkelly/2019/05/30/heres-why-following-the-herd-could-kill-your-career/#42cdf738b6ae' />
      </div>
  </div>
}