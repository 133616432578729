import React, { Component } from "react";
import { signOut } from "../../containers/auth/redux_actions";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "@material-ui/core";

let Footer = () => {
  let { userAccount } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery("(min-width:980px)");

  return (
    <div
      className="footer"
      style={{ backgroundColor: isDesktop ? "white" : "#F7F7F7" }}
    >
      {!isDesktop && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "25px",
          }}
        >
          <div
            style={{
              fontSize: 13,
              cursor: "pointer",
              lineHeight: "19px",
              color: "#979797",
            }}
          >
            {userAccount && userAccount.name ? userAccount.name : null}
          </div>
          <span
            style={{
              fontSize: 13,
              cursor: "pointer",
              lineHeight: "19px",
              color: "#979797",
            }}
          >
            &nbsp; | &nbsp;
          </span>
          <div
            style={{ fontSize: 12, cursor: "pointer", lineHeight: "19px" }}
            onClick={() => {
              dispatch(signOut());
            }}
          >
            Logout
          </div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          borderTop: isDesktop ? "none" : "2px solid #dddddd",
          paddingTop: "1rem",
          alignItems: "center",
          justifyContent: isDesktop ? "flex-end" : "center",
        }}
      >
        <div
          style={{
            color: "#8E8E8E",
            font: "normal normal normal 11px/22px Merriweather Sans",
          }}
        >
          Copyright 2018; Illumine. All Rights Reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;
