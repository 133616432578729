import React from 'react';
import ExternalResource from '../components/ExternalLink';
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return <div>
    <p >
    Do you see success as a goal to reach or measure it by the things you own? Is that truly success? The video below answers these queries and also gives you a new perspective on measuring success. 
    </p>
    <div className='f15_b' style={{ marginBottom: "20px" }}>
    While watching the video below, please pay special attention to the following:
    </div>
    <ul>
      <li>The importance given to keep things going</li>
      <li>The role momentum and growth play in your career</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url=' https://www.youtube.com/watch?v=xyKdxXS3dLo' />
    </div>
  </div>
}