import React from "react";
import ExternalResource from "../components/ExternalLink";
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return (
    <div>
      <p>
        The world expects you to be many things. But what is it that you want to
        be? In this video, you will be given an interesting perspective on how
        to assemble and make sense of all the varied inputs we get from the
        outside world.
      </p>
      <div className="f15_b" style={{ marginBottom: "10px" }}>
        While listening to the video pay special attention to the following:
      </div>
      <ul>
        <li>
          Focusing on yourself and your own growth requires you to step away
          from constant input from the outside world and dwell on your own
          journey of growth.
        </li>
        <li>
          The metaphor of the tree, which asks you to connect your roots and
          shoots so that you can evolve and express your true self.
        </li>
      </ul>

      <div className="highlighted-patch">
        <div className="f15_b">Links</div>
        <ExternalResource url="https://www.youtube.com/watch?v=R2ZSuEOKiRQ&list=TLPQMDkwOTIwMjD-8lPEBmMz1Q&index=1" />
      </div>
    </div>
  );
};
