import React from 'react';
import ExternalResource from '../components/ExternalLink';
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return <div>
    <p>The videos below offers viewers six different ideas to find a fulfilling career and a deeper understanding of what it means to have a meaningful work life.</p>
    <div className='f15_b' style={{ marginBottom: "20px" }}>
    While watching the video, think about:
    </div>
    <ul>
      <li>The role fear may play when we choose a career.</li>
      <li>The idea of “financial panic killing all dialogue with the more authentic side of one’s nature”.</li>
      <li>The idea of thinking of work as an act of service.</li>
      <li>Understanding your authentic working identity – working harder when your deep self is engaged.</li>
    </ul>

    <div className='f15_b' style={{ marginBottom: "20px" }}>
    Based on this think about:
    </div>
    <ul>
      <li>How would a ‘contributive career strategy’ as opposed to an ‘acquisitive career strategy’ enable you to lead a meaningful work life? </li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.youtube.com/watch?v=veriqDHLXsw&feature=youtu.be' />
      <ExternalResource url='https://www.youtube.com/watch?v=H91JDqeR_jg' />
      </div>
  </div>
}