import React from 'react';
import ExternalResource from '../components/ExternalLink';
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return <div>
    <p >
    This is the story of Rhianna Gunn Wright, Director of Climate Policy at the Roosevelt Institute and one of the authors of the climate policy bill, the Green New Deal. Because of her own personal experiences, Gunn Wright was able to bring a new perspective to addressing issues related to climate change and think of a new approach to solving issues related to climate change.
    </p>
    <div className='f15_b' style={{ marginBottom: "20px" }}>
    Keep in mind the following while watching the video:
    </div>
    <ul>
      <li>Her motivations to make an impact on climate change policy.</li>
      <li>Rhianna’s attitude to tackling issues, moving from being a victim of climate change to actually becoming a creator of her own destiny.</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.youtube.com/watch?v=0uThg0BRDU0&feature=youtu.be' />
    </div>
  </div>
}