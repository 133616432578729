import React from 'react';
import ExternalResource from '../components/ExternalLink';
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return <div>
    <p >
    In a globalised world, adapting to new cultures, new ways of working takes a level of humility, understanding and acceptance. Adapting to a new culture, however is a process and it takes time and effort and a non-judgmental outlook. Read more in the article below.
    </p>
    <div className='f15_b' style={{ marginBottom: "20px" }}>
    While reading the article keep an eye out for the following:
    </div>
    <ul>
      <li>How important it is to shift your own mindset while dealing with a new culture.</li>
      <li>The ways in which  the writer developed new approaches to work in a new culture .</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.noomii.com/articles/2414-culture-shock-my-story' />
    </div>
  </div>
}