import React from 'react';
import ExternalResource from '../components/ExternalLink';
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return <div>
    <p >
    Passion, natural talent, outside expectations – we often think of many of these before settling on a specific career. Here are a few personal stories of people who changed gears in their careers to serve a greater purpose through their careers.   
    </p>
    <div className='f15_b' style={{ marginBottom: "20px" }}>
    While reading the articles below, pay special attention to: 
    </div>
    <ul>
      <li>Sonam Wangchuk’s desire to serve his people’s educational needs and bring more innovation to the country.</li>
      <li>Focus on the questions that Ashley Stahl asks the audience to consider, while choosing a career.</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.youtube.com/watch?v=vMiSf7LpFQE' />
      <ExternalResource url='https://www.dnaindia.com/health/interview-issue-is-herd-mentality-higher-education-has-failed-to-serve-india-2670067' />
      </div>
  </div>
}