import React from 'react';
import ExternalResource from '../components/ExternalLink';
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return <div>
    <p >
    It is easy to think of your career as only a journey of growth - a cycle of promotions and rising pay checks along with increased revenue for your company. In the corporate world, growth and progress are usually hailed as one of the most important aspects of running a business. However, for institutions to endure, purpose and empathy are as important or even more important than growth figures.   
    </p>
    <div className='f15_b' style={{ marginBottom: "20px" }}>
    While going through the videos below, pay special attention to:
    </div>
    <ul>
      <li>How Satya Nadella, CEO, Microsoft focuses on building a collaborative work environment, ensuring his employees feel empowered.</li>
      <li>How Satya Nadella is focused on empathizing with the needs of his customers, so that his company can serve them better.</li>
      <li>The fact that Satya Nadella says that you need to look at “success outside of the balance sheet” and focus on the impact you make in the lives of people, how does he expand his vision of success in this case? </li>
      <li>The tremendous impact Satya Nadella’s viewpoint has had on Microsoft’s growth. </li>
      <li>The importance of prioritizing people over profit, ensuring that companies focus on enabling their employees and serving them, as articulated by Simon Sinek.</li>
    </ul>

    <div className='f15_b' style={{ marginBottom: "20px" }}>
    Based on these insights, reflect:
    </div>
    <ul>
      <li>What if you approach your career with a ‘Contributor identity’ or a vision of yourself as having ‘infinite possibilities’? How would your career story look?</li>
    </ul>


    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.youtube.com/watch?v=nq8rEeJ4Tak' />
      <ExternalResource url='https://www.youtube.com/watch?v=6tyCydR30pg' />
      <ExternalResource url='https://www.youtube.com/watch?v=8Yrmopm5Npc' />
      </div>
  </div>
}