/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Grid, useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { ArrowBack, Brightness1, Launch } from "@material-ui/icons";
import explorations from "../../explorations";
import "./explore.css";
const Explore = (props) => {
  let { myModules, units } = useSelector((state) => state.app);
  let { uId, mId } = props.match.params;

  const md = myModules[mId];
  let unit = units[uId];
  const history = useHistory();
  const isDesktop = useMediaQuery("(min-width:980px)");
  return (
    <React.Fragment>
      <div className={isDesktop ? "desktop" : "mobile"}>
        <Header />
        <div
          style={{
            ...(isDesktop
              ? { padding: "0px 106px 0 24px" }
              : { padding: "0 24px" }),
          }}
        >
          <div
            style={{
              color: "#858585",
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              cursor: "pointer",
              float: "left",
            }}
            onClick={() => history.push(`/app/module/${mId}`)}
          >
            <ArrowBack style={{ marginRight: "5px" }} /> Back to Home
          </div>
          <Grid container>
            <Grid item lg={4} md={4} sm={4} xs={12}>
              <div
                style={{
                  font: "normal normal bold 22px/28px Merriweather",
                  marginBottom: "25px",
                  lineHeight: "1.6",
                  ...(isDesktop ? { paddingRight: "120px" } : ""),
                }}
              >
                {unit.title}
              </div>
              {isDesktop && (
                <div style={{ width: "51px" }}>
                  <img
                    src={unit.icon}
                    alt="unit-icon"
                    style={{ width: "100%" }}
                  />
                </div>
              )}
            </Grid>
            <Grid item lg={8} md={8} sm={8} xs={12}>
              <Grid container>
                <Grid item lg={8} md={8} sm={8} xs={12}>
                  <div
                    className="external-links"
                    style={{
                      ...(isDesktop
                        ? { borderTop: "1px solid #bebebe", paddingTop: "25px" }
                        : ""),
                    }}
                  >
                    {explorations[unit.exploration]
                      ? explorations[unit.exploration]()
                      : null}
                  </div>
                </Grid>
              </Grid>
              <div
                style={{
                  font: "normal normal bold 14px/20px Merriweather Sans",
                  marginBottom: "25px",
                  textTransform: "uppercase",
                }}
              >
                Continue Exploring
              </div>
              <Grid container spacing={3}>
                {Object.keys(md.units).map((_uId, i) => {
                  const isTopBorder = isDesktop
                    ? i === 0 || i === 1 || i === 2
                      ? true
                      : false
                    : i === 0 || i === 1
                    ? true
                    : false;
                  return (
                    <Grid
                      key={_uId}
                      item
                      md={4}
                      lg={4}
                      xs={6}
                      style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        ...(uId === _uId ? { display: "none" } : ""),
                      }}
                    >
                      {unitCard(units[_uId], mId, _uId, isTopBorder)}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </div>

        <Footer />
      </div>
    </React.Fragment>
  );
};

function unitCard(unit, mId, uId, isTopBorder) {
  return (
    <Link
      key={uId}
      to={`/app/module/${mId}/explore/${uId}`}
      onClick={(e) => window.scrollTo(0, 0)}
      style={{
        flex: "0 1 150px",
        ...(isTopBorder ? { borderTop: "1px solid #bebebe" } : ""),
        borderBottom: "1px solid #bebebe ",
        padding: "30px 0 70px",
        display: "block",
        color: "inherit",
        textDecoration: "none",
        height: "100%",
        position: "relative",
      }}
    >
      <div
        style={{
          paddingBottom: "10px",
          width: "38px",
          height: "38px",
          marginBottom: 13,
        }}
      >
        <img src={unit.icon} alt="icon" style={{ width: "100%" }} />
      </div>
      <div
        className="line-clamp line-clamp"
        style={{
          font: "normal normal bold 15px/23px Merriweather",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          lineClamp: "4",
          boxOrient: "vertical",
          marginBottom: "20px",
          overflow: "hidden",
          lineHeight: 1.6,
        }}
      >
        {unit.title}
      </div>
      <div
        style={{
          color: "#E6111B",
          font: "normal normal 800 11px/22px Merriweather Sans",
          textAlign: "right",
          position: "absolute",
          right: 0,
          bottom: "10px",
        }}
      >
        VIEW
      </div>
    </Link>
  );
}

export default Explore;
