import React from 'react';
import ExternalResource from '../components/ExternalLink';
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return <div>
    <p >
    Ubuntu is an African word for a universal concept. The word Ubuntu  embodies  a  distinctive  world  view  of  the  human  community and the identities, values, rights, and responsibilities of its members. It is about “we” – not “me.” Ubuntu is the potential for being human, to value the good of the community above self-interest.
    </p>
    <div className='f15_b' style={{ marginBottom: "20px" }}>
    While reading this article pay special attention to:
    </div>
    <ul>
      <li>The idea of looking after one another, as a society and how it helps build a better society.</li>
      <li>What Ubuntu tells us about justice and equality.</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url=' https://www.globalcitizen.org/en/content/ubuntu-south-africa-together-nelson-mandela/' />
    </div>
  </div>
}