import React from 'react';
import ExternalResource from '../components/ExternalLink';
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return <div>
    <p >
    There is no expiry date on achieving success and no one knows this better than Chanro Tomar, who decided to begin her shooting career at the age of 65. In the article below, we learn more about her journey, and how she redefined what success meant to her.
    </p>
    <div className='f15_b' style={{ marginBottom: "20px" }}>
    While reading the article pay special attention to:
    </div>
    <ul>
      <li>The factors that led Chanro Tomar to believe in her own abilities to compete professionally.</li>
      <li>Her response to family and people close to her who discouraged from pursuing shooting</li>
      <li>What her self-belief resulted in for the community</li>      
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.thebetterindia.com/98987/shooter-dadi-chanro-tomar-shooting-uttar-pradesh/' />
    </div>
  </div>
}