import React from 'react';
import ExternalResource from '../components/ExternalLink';
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return <div>
    <p >
    The article below is a book review of Learned Hopefulness: The Power of Positivity to Overcome Depression by Dan Tomasulo. The article explores the idea of moving away from the deficiency realm to the being realm. 
    </p>
    <div className='f15_b' style={{ marginBottom: "20px" }}>
    While reading the article, pay special attention to the following:
    </div>
    <ul>
      <li>Traveling from the deficiency realm to the being realm.</li>
      <li>The three findings on hope that the author thinks can help people approach life with more positivity.</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://blogs.scientificamerican.com/beautiful-minds/review-of-learned-hopefulness-the-power-of-positivity-to-overcome-depression/' />
    </div>
  </div>
}