import React from "react";
import ExternalResource from "../components/ExternalLink";
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return (
    <div>
      <p>
        Albert Bandura’s theory of self- efficacy talks about an individual’s
        belief in his own ability to accomplish set goals. In the article below,
        you will dive deeper into understanding the Self-Efficacy theory and
        understand how self-efficacy can be developed. Understanding how
        self-efficacy works can be used effectively to break old patterns of
        thinking and helps you discover your true potential.
      </p>
      <div className="f15_b" style={{ marginBottom: "10px" }}>
        While reading the article below, pay particular attention to the
        following:
      </div>
      <ul>
        <li>
          The difference between people with a strong sense of self-efficacy and
          a weak sense of self-efficacy
        </li>
        <li>The four major sources of self-efficacy </li>
        <li>The ways in which you can build self-efficacy</li>
      </ul>

      <div className="highlighted-patch">
        <div className="f15_b">Links</div>
        <ExternalResource url="https://www.verywellmind.com/what-is-self-efficacy-2795954" />
      </div>
    </div>
  );
};
