import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useSelector } from "react-redux";
import FirstTimeUserMessage from "./firstTimeUserMsg";
import MyModules from "./MyModules";
import { useMediaQuery } from "@material-ui/core";
export default () => {
  let { settings } = useSelector((state) => state.public);
  let app = useSelector((state) => state.app);
  const isDesktop = useMediaQuery("(min-width:980px)");
  Object.keys(app.myModules || {}).forEach((k) => {
    if (!app.myModules[k]) delete app.myModules[k];
  });

  return (
    <div className={isDesktop ? "desktop" : null}>
      <Header />
      <div
        style={{
          padding: "0px 24px",
          ...(isDesktop
            ? { margin: "52px 106px 141px" }
            : { marginBottom: "90px" }),
        }}
      >
        <div
          style={{
            font: "normal normal normal 16px/26px Merriweather Sans",
            // paddingBottom: 24,
            marginBottom: 48,
            color: "#505050",
            ...(isDesktop ? { width: "50%" } : ""),
          }}
        >
          {settings&&settings.site_description?settings.site_description:null}
        </div>
        <div>
          {app && app.myModules ? (
            <MyModules modules={app.myModules} />
          ) : (
            <FirstTimeUserMessage user={{ uid: app.userId }} />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};
