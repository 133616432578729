import React from 'react';
import ExternalResource from '../components/ExternalLink';
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return <div>
    <p >
    In the article below, researchers look at the factors that contribute to enduring success. The aim of the article is to shift the ways in which people frame success and not look at success as a series of goals at work, but as something more durable. 
    </p>
    <div className='f15_b' style={{ marginBottom: "20px" }}>
    While reading the article pay special attention to:
    </div>
    <ul>
      <li>The Kaleidoscopic approach to success and the benefits it offers.</li>
      <li>The meaning behind the idea of “on the reasoned pursuit of just enough.”</li>
          </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://hbr.org/2004/02/success-that-lasts' />
    </div>
  </div>
}