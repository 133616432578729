import React from "react";
import ExternalResource from "../components/ExternalLink";
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return (
    <div>
      <p>
        Discovering your passion can give you a new sense of purpose. These are
        stories of people who unlocked their potential in the most unexpected
        ways.
      </p>
      <div className="f15_b" style={{ marginBottom: "20px" }}>
        While reading keep an eye out for:
      </div>
      <ul>
        <li>
          The “Why not” attitude, which surfaces in many stories, For eg. When a
          celtic harpist offers to train Anastasia, she does not shy away but
          looks upon the opportunity as way to challenge herself and hone her
          musical skills.
        </li>
        <li>
          Dark and unanticipated moments turned transformational, journeys for
          eg. Losing a job led Madylene Planer to shift and grow in a new
          career.
        </li>
      </ul>

      <div className="highlighted-patch">
        <div className="f15_b">Links</div>
        <ExternalResource url="https://thriveglobal.com/stories/unexpected-passion-self-discovery-unlock-potential-stories-anecdotes/" />
      </div>
    </div>
  );
};
