import React from 'react';
import ExternalResource from '../components/ExternalLink';
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return <div>
    <p >
    Democratic societies are committed to the values of tolerance, cooperation, and compromise. In the words of Mahatma Gandhi, “Intolerance is itself a form of violence and an obstacle to the growth of a true democratic spirit.” Thus, at the heart of a democracy lies a sense of ‘citizenship identity’ amongst its citizens that helps drive change.
    </p>
    <div className='f15_b' style={{ marginBottom: "20px" }}>
    While reading the article pay special attention to:
    </div>
    <ul>
      <li>The factors that build a unifying citizen identity, in this case, freedom of speech.</li>
      <li>The motivations of varied groups of people to assert their citizen identity in this case.</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://www.washingtonpost.com/world/hundreds-of-thousands-are-expected-to-march-in-paris-solidarity-rally/2015/01/11/3befc7e2-996e-11e4-927a-4fa2638cd1b0_story.html?hpid=z2' />
    </div>
  </div>
}