import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./0.Home";
import Explorium from "./1.Explorium";
import Module from "./2.Module";
import Explore from "./3.Explore.js";
import Resources from "./4.Resources.js";
import "./redux_actions";
export default () => {
  return (
    <Switch>
      <Route exact path="/app/module/:mId" component={Module} />
      <Route exact path="/app/module/:mId/explore/:uId" component={Explore} />
      <Route exact path="/app/explorium" component={Explorium} />
      {/* <Route exact path="/app/resources" component={Resources} /> */}
      <Route exact path="/app" component={Explorium} />
    </Switch>
  );
};
