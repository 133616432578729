import React from 'react';
import ExternalResource from '../components/ExternalLink';
import { Brightness1, Launch } from "@material-ui/icons";
export default (props) => {
  return <div>
    <p >
    The following article showcases 21 questions that everyone needs to ask themselves to understand the kind of success they want.
    </p>
    <div className='f15_b' style={{ marginBottom: "20px" }}>
    While going through the article notice the following about yourself:
    </div>
    <ul>
      <li>If these questions are easy for you to answer</li>
      <li>How many times external markers of success (eg. Promotions, higher salary, nice vacation) showed up while answering these questions</li>
    </ul>

    <div className='highlighted-patch'>
      <div className='f15_b'>Links</div>
      <ExternalResource url='https://thriveglobal.com/stories/these-21-questions-are-guaranteed-to-make-you-more-successful' />
    </div>
  </div>
}